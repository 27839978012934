var config = {
  // Set the Demo Version
  demo: false,

  // googleAddsense
  // googleAddsense: "",

  //SEO Configurations
  appName: "Crazy Brain",
  metaDescription: "Crazy Brain is a Web Quiz Application",
  metaKeyWords: "Crazy Brain,Quiz,Questions,Answers,Online Quiz",
  //API Configurations
  apiAccessKey: 8525,
  // apiUrl: "https://elitequiz.wrteam.in/api/",
  apiUrl: "https://crazybrain.net/admin/api/",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "ur"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "in", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  // matchrandomBattleSeconds: 30,
  battlecorrectanswer: 4,
  randomBattleSeconds:30, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 2, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds

  //Firebase Configurations
  apiKey: "AIzaSyCtF0acxqe1ZUQkPjxHSKDYENz2rqcfUX8",
  authDomain: "carzybraingame.firebaseapp.com",
  projectId: "carzybraingame",
  storageBucket: "carzybraingame.appspot.com",
  messagingSenderId: "531121066437",
  appId: "1:531121066437:web:fe7a8e25dcbff8a7677bac",
  measurementId: "G-GE6XESW46J",

  //Firebase Configurations

  // apiKey: "XXXXXXXXXXXXXXXXXXXXX",
  // authDomain: "XXXXXXXXXXXXXXXXX",
  // projectId: "XXXXXXXXXXXXXXXXXX",
  // storageBucket: "XXXXXXXXXXXXXX",
  // messagingSenderId: "XXXXXXXXXX",
  // appId: "XXXXXXXXXXXXXXXXXXXXXX",
  // measurementId: "XXXXXXXXXXXXXX",

  //footer area
  companytext: "CrazyBrain Trivia Game is a platform that offers a variety of trivia options, including daily trivia, group trivia, and more. We are a team of trivia enthusiasts who are passionate about bringing exciting and challenging trivia experiences to players around the world. Our goal is to create a fun and enjoyable trivia experience for all of our players. Thank you for choosing CrazyBrain Trivia Game!.",
  addresstext: "Address: #262-263, Time Square Empire, SH 42 Mirzapar highway,  Bhuj - Kutch 370001 Gujarat India.",
  phonenumber: "+91 9797945459",
  email: "hello@crazybrain.net",
  facebooklink: "https://www.facebook.com/wrteam.in",
  instagramlink: "https://www.instagram.com/wrteam.in",
  linkedinlink: "https://www.linkedin.com/company/wrteam",
  weblink: "https://crazybrain.net/",
  companyname: "Crazy Brain",
};

export default config;
